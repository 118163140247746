div.ytcontainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
iframe.yt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.hcHomeSection {
  background-color: red;
  border: solid 1px black;
  text-align: center;
}

.hcrImageBox, .hcCard {
  border: 2px solid $brand-color;
  border-radius: 10px;
  padding: 10px;
  background-color: $grey-color-light;
  margin-bottom: 10px;
  margin-right: 10px;
}

.hcCard {
  border: 2px solid $brand-color;
  border-radius: 10px;
  padding: 10px;
  background-color: $grey-color-light;
}

.hcrImageBox img, #hcrFeaturedImageBox img, .hcCard img, .hcrSoloImage img {
  border: 2px solid $text-color;
  display: block;
  margin: 0 auto;
}

.hcrLegacyImage {
  border: 2px solid $text-color;
}

#hcrContentBox {
  border: 2px solid $brand-color;
  border-radius: 10px;
  padding: 10px;
  background-color: $grey-color-light;
}

#hcrFeaturedImage {
    border: 2px solid $text-color;
}

/*
#hcrFeaturedImageBox {
  position: absolute;
  float: right !important;
  top: -201px;
  border: 2px solid #FF0000;
}
*/

.hcrTable {
  border: 1px solid $text-color;
}

.hcrTable td, .hcrTable th {
  border: 1px solid $text-color;
  padding: 2px;
}


.hcrCarousel img {
  border: 2px solid $text-color;
}

.slick-prev:before,
.slick-next:before
{
  color: $brand-color;
}
.slick-prev {
  left: -35px;
  z-index: 1;
}
.slick-next {
  right: -35px;
  z-index: 1;
}

.slick-slide {
  margin: 0 5px;
}
.slick-list {
  margin: 0 -5px;
}
